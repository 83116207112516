<!--
 * @Author: your name
 * @Date: 2022-05-01 15:09:04
 * @LastEditTime: 2022-05-16 15:39:11
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\pages\user\components\hotTools.vue
-->
<!--
 * @Author: your name
 * @Date: 2022-03-30 10:19:20
 * @LastEditTime: 2022-04-01 15:40:53
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\pages\home\components\hotTools.vue
-->

<template>
  <div class="hotTool">
    <div class="toolItem">
      <el-tabs v-model="title" @tab-click="tabChange" class="demo-tabs">
        <el-tab-pane name="热门文章">
          <template #label>
            <span class="custom-tabs-label">
              文章({{ articleTotal }})
            </span>
          </template>

          <!-- /文章列表 -->
          <div
            class="_artile"
            :style="{ padding: flexScreen == 'mob' ? '0' : '0 30px' }"
            v-if="articleList.length"
          >
            <ArticleList
              v-show="!autoFlag"
              class="clearfix"
              :style="{ padding: flexScreen == 'mob' ? '10px' : '10px 30px' }"
              v-for="item in articleList"
              :key="item.id"
              :article="item"
            >
            </ArticleList>
            <div
              class="loadMoreHome"
              v-show="moreFlag && articleList.length < articleTotal"
            >
              <el-button
                size="small"
                type="default"
                @click="getMore()"
                :loading="moreLoading"
              >
                加载更多
              </el-button>
            </div>
          </div>
          <!-- 暂无文章 -->
          <div v-else class="withoutImg">暂无文章</div>
          <!-- 文章到底了 -->
          <div
            class="without"
            v-show="
              (!moreFlag || articleList.length == articleTotal) &&
              articleList.length
            "
          >
            -- 已经到底了 --
          </div>
        </el-tab-pane>

        <!-- 图片 -->
        <el-tab-pane name="图片">
          <template #label>
            <span class="custom-tabs-label"> 图片({{ imgTotal }}) </span>
          </template>
          <div v-if="imgTotal">
            <Loading v-if="!loadingFlag" :zIndex="999"></Loading>
            <WaterFall
              ref="WaterFallRef"
              v-if="waterFallShow"
              :list="imgList"
              :loadingBtn="loadingBtn"
              :loadingWithout="loadingWithout"
              :isLoad="isLoad"
              @load="load"
              @vnode-unmounted="waterFallUnmounted"
              @vnode-mounted="waterFallMounted"
            >
              <template #slotTest3="scope">
                <div
                  class="imgModal"
                  @click="
                    $router.push({
                      name: 'phoDetail',
                      params: { id: scope.item.id },
                    })
                  "
                >
                  <img
                    :src="scope.item.img[0].imgurl"
                    v-seen="{
                      type: 'lazy',
                      height: getHeight(
                        scope.width,
                        scope.item.img[0],
                        scope.index
                      ),
                    }"
                    :style="{
                      width: '100%',
                      height: getHeight(
                        scope.width,
                        scope.item.img[0],
                        scope.index
                      ),
                    }"
                    alt=""
                  />
                  <div class="imgModalItem">
                    <span>
                      图集 * {{ scope.item.img[0].children.length }}
                    </span>
                  </div>
                </div>
                <div class="imgTitle">
                  <span>{{ scope.item.title }}</span>
                </div>
                <div class="desription">
                  <span
                    v-for="(item, index) in scope.item.tag_name.split(',')"
                    :key="index"
                    >#{{ item }}
                  </span>
                </div>
                <div class="imgTool">
                  <ArticleToolVue
                    class="ArticleToolVue"
                    :likeNum="
                      getIsLiked(scope.item.id)?.num || scope.item.likeNum
                    "
                    :commentNum="scope.item.commentNum"
                    @pingFn="pingFn(scope.item.id)"
                    @likeFn="likeFn(scope.item)"
                    :isLikedMe="
                      !!getIsLiked(scope.item.id)
                        ? getIsLiked(scope.item.id).isLiked
                        : scope.item.isLiked
                    "
                  />
                </div>
              </template>
            </WaterFall>
          </div>

          <div v-else class="withoutImg">暂无图片</div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script setup>
import WaterFall from "@/pages/photo/components/waterFall.vue";
import { Pointer, ChatLineSquare, MagicStick } from "@element-plus/icons-vue";
import {
  defineProps,
  defineEmits,
  onMounted,
  ref,
  onActivated,
  onDeactivated,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ArticleTool from "@/components/articleTool/articleTool";
import ArticleList from "@/components/articleList/articleList";
import Photo from "@/pages/photo/index";
import useScreen from "@/hooks/useScreen.js";
import { debounce, sleep, throllte } from "@/utils/public.js";
import { getRandomImg, like, likeCancel, isLikedFn } from "@/api/api.js"; //连接接口文件
import { useGetters } from "@/store/utils/useGetters.js";
import Loading from "@/components/loading/loading.vue";
import ArticleToolVue from "@/components/articleTool/articleTool.vue";
import { isLogin, updateLoginShow } from "@/utils/user";
let router = useRouter();
let store = useStore();
let title = ref("热门文章");
let loadingBtn = ref(false);
let loadingWithout = ref(false);
let scrollFlag = ref(false);
let WaterFallRef = ref(null); //waterFall组件ref
let loadingFlag = ref(false); // 控制waterFall组件里的loading
let waterFallShow = ref(false); // 控制waterFall组件
let moreLoading = ref(false);
let moreFlag = ref(true);
let isLoad = ref(false);

let { flexScreen } = useScreen();
let limit = ref(10);
let current = ref(0);
let props = defineProps({
  articleTotal: {
    type: Number,
    default: 0,
  },
  articleList: {
    type: Array,
    default: () => {
      return [1];
    },
  },

  imgTotal: {
    type: Number,
    default: 0,
  },
  imgList: {
    type: Array,
    default: () => {
      return [1];
    },
  },
});

let emits = defineEmits("[getImgFn,getMore]");

// 加载更多文章
let getMore = () => {
  emits("getMore", () => {
    moreFlag.value = false;
  });
};

// 获取元素高度
let getHeight = (width, img, index) => {
  // 获取图片宽高像素
  let { w, h } = img;
  //获取元素宽度
  let n = width;
  // 计算图片宽高比
  let scale = h / w;
  return n * scale - 16 - 2 + "px";
  // 设置假高度
  // document.getElementsByClassName("imgId")[index].style.height = n * scale - 16 - 2 + 'px'
};

// 切换tab触发
let tabChange = (e) => {
  console.log(e.index);
  if (e.index == 1) {
    if (!loadingFlag.value) {
      waterFallShow.value = true;
    }
    isLoad.value = true;
  } else {
    isLoad.value = false;
  }
};

// 追加图片
let pushImg = async (pushFn) => {
  try {
    loadingBtn.value = true;
    emits("getImgFn", WaterFallRef.value.pushDom, (isBottom = false) => {
      loadingBtn.value = false;
      scrollFlag.value = true;
      console.log("isBottomisBottomisBottom", isBottom);
      if (isBottom) {
        loadingWithout.value = true;
      }
      if (!loadingFlag.value) {
        setTimeout(() => {
          console.log("关闭loading");
          loadingFlag.value = true;
        }, 1500);
      }
    });
  } catch (err) {
    loadingBtn.value = false;
    loadingWithout.value = true;
  }
};

// 触底加载函数
const load = (pushFn) => {
  console.log("cccccccccccccccccc");
  throllte(
    () => {
      if (scrollFlag.value && !loadingWithout.value) {
        console.log("pushImg");
        pushImg(pushFn);
      }
    },
    300,
    true
  );
};

// 去文章详情页
let toPhoto = (params) => {
  router.push({
    path: `/phoDetail/${params.id}`,
  });
};

// 点击评论跳转到文章
let pingFn = (id) => {
  store.commit("postInfo/UP_isFromComment", true);
  toPhoto({ id });
};

// 点赞
let likeFn = (item) => {
  // 判断是否登陆
  if (!isLogin()) {
    updateLoginShow(true);
    return;
  }

  like({ id: Number(item.id), type: 3 }).then((res) => {
    if (res.msg === "你已点过赞了！") {
      likeCancel({ id: Number(item.id), type: 3 }).then((canRes) => {
        item.isLiked = false;
        item.likeNum--;
        store.commit("imgInfo/UP_LIKELIST", {
          type: "reduce",
          value: {
            id: item.id,
            num: item.likeNum,
          },
        });
      });
      return;
    }
    item.isLiked = true;
    item.likeNum++;
    store.commit("imgInfo/UP_LIKELIST", {
      type: "add",
      value: {
        id: item.id,
        num: item.likeNum,
      },
    });
  });
};

let { myLikeImgList } = useGetters("imgInfo", ["myLikeImgList"]);

// 图片喜欢状态
let getIsLiked = (id) => {
  let flag = myLikeImgList.value.find((item) => item.id == id);
  return flag;
};

let waterFallMounted = () => {
  pushImg();
};

let waterFallUnmounted = () => {
  loadingFlag.value = false;
};

onActivated(() => {
  scrollFlag.value = true;
});

onDeactivated(() => {
  scrollFlag.value = false;
});
</script>

<style lang="scss"  scoped>
.withoutImg {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: var(--white_divFc);
}
.loadMoreHome {
  margin-top: 5px;
  width: 100%;
  .el-button {
    padding: 8px 30px !important;
    background-color: var(--white_divBgc) !important;
    color: var(--white_divFc) !important ;
    border: 1px solid var(--white_divBgc) !important;
  }
}

.without {
  padding: 10px 50px;
  color: var(--white_divFc);
  font-size: 14px;
}
.magic {
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
.ArticleSmall {
  padding: 0px 0;
  border-radius: 0;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .without {
    padding: 10px 0;
    color: var(--white_divFc);
    font-size: 14px;
  }
  .loadMoreHome {
    margin-top: 5px;
    width: 100%;
    .el-button {
      padding: 8px !important;
      background-color: var(--white_divBgc) !important;
      color: var(--white_divFc) !important ;
      border: 1px solid var(--white_divBgc) !important;
    }
  }
  .hotTool {
    padding: 0 !important;
  }
}
.hotTool * {
  box-sizing: border-box;
}
.hotTool {
  margin-top: 23px;
  padding: 0 0 0 0px;
  color: var(--white_divFc);
  .toolItem {
    margin-top: 15px;
    background-color: var(--white_divBgc);
    color: var(--white_divFc);
    height: auto;
    border-radius: 10px;
    padding: 5px;
    box-sizing: border-box;
  }

  ._artile {
    padding: 0 30px;
    .clearfix {
      padding: 30px;
      ::v-deep .message {
        // background-color: rgba($color: rgb(0, 0, 0), $alpha: 0.2);
        // background-color: rgba($color: #f5f5f5, $alpha: 1);
        // background-color: #000000;
        background-color: var(--white_divBgc_light1);
      }
    }
  }

  // ._artile {
  //   height: auto;
  //   ._artileItem {
  //     display: flex;
  //     height: auto;
  //     min-width: 0;
  //     border-bottom: 1px solid var(--white_divBoc);
  //     border-radius: 0;
  //     ._artileItem_img {
  //       width: 80px;
  //       height: 80px;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       img {
  //         width: 50px;
  //         height: 50px;
  //         border-radius: 3px;
  //       }
  //     }

  //     ._artileItem_right {
  //       flex: 1;
  //       display: flex;
  //       flex-direction: column;
  //       min-width: 0;
  //       .title {
  //         padding: 0;
  //         height: 50px;
  //         display: flex;
  //         justify-content: flex-start;
  //         align-items: center;
  //         text-indent: 10px;
  //         span {
  //           font-size: 14px;
  //           max-height: 50px;
  //           display: inline-block;
  //           white-space: nowrap;
  //           overflow: hidden;
  //           text-overflow: ellipsis;
  //         }
  //       }
  //       .tool {
  //         padding: 0;
  //         height: 30px;
  //         max-height: 30px;
  //         font-size: 8px;
  //         display: flex;
  //         justify-content: flex-start;
  //         align-items: center;
  //       }
  //     }
  //   }
  // }
}

::v-deep .el-tabs__active-bar {
  width: 45% !important;
}
::v-deep .el-tab-pane {
  position: relative;
}
::v-deep .custom-tabs-label {
  color: var(--white_divFc) !important;
}
::v-deep .demo-tabs > .el-tabs__content {
  padding: 0 10px;
  color: var(--white_divFc);
  font-size: 24px;
}
::v-deep .demo-tabs .custom-tabs-label .el-icon {
  vertical-align: middle;
}
::v-deep .demo-tabs .custom-tabs-label span {
  vertical-align: middle;
  margin-left: 4px;
}
::v-deep .el-tabs__item {
  /* padding: 0 20px; */
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  color: var(--el-text-color-primary);
  position: relative;
  flex: 1 !important;
  justify-content: center !important;
  display: flex !important;
}

::v-deep .el-tabs__nav-wrap::after {
  width: 0 !important;
}
::v-deep .el-tabs__active-bar {
  background-color: var(--white_divBoc) !important;
}

::v-deep .el-tabs__nav {
  margin: 0 auto !important;
  width: 80% !important;
  display: flex !important;
}
::v-deep .el-tabs__nav-scroll {
  display: flex !important;
}
</style>
