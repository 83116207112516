<template>
  <div class="articleList">
    <div class="img">
      <img class="el-image" v-seen="{ type: 'lazy' }" :src="article.img" />
    </div>

    <div class="message">
      <div class="title">
        {{ article.title }}
      </div>

      <div class="time">
        {{ article.created_at }}
      </div>

      <div class="des">
        {{ article.shortContent }}
      </div>

      <div
        class="tool"
        v-seen="{ type: 'animate', class: 'animate__fadeInUp' }"
      >
        <el-button type="danger" size="small" @click="toPosts()">
          我要阅读
        </el-button>
        <ArticleTool
          @likeFn="likeFn(article.id)"
          @pingFn="pingFn"
          :likeNum="likeNum"
          :commentNum="commentNum"
          :isLikedMe="isLikedMe"
        >
        </ArticleTool>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useIntersectionObserver } from "@vueuse/core";

import ArticleTool from "../articleTool/articleTool.vue";
import useLike from "../header/useLike";

export default {
  name: "articleList",
  components: {
    ArticleTool,
  },
  props: {
    article: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props) {
    let { article } = toRefs(props);
    let router = useRouter();
    let store = useStore();
    let useLikes = useLike();

    // 去文章详情页
    let toPosts = (params) => {
      router.push({
        name: "posts",
        params: { id: article.value.id, ...params },
      });
    };

    // 点赞量
    let likeNum = computed(() => {
      // 获取缓存中的文章列表
      let postsList = store.getters["postInfo/postsList"];

      // 判断缓存中是否有此文章
      let isHas = postsList.filter((item) => item.id == article.value.id);

      if (isHas.length) {
        return isHas[0].likeNum;
      }
      return article.value.likeNum;
    });

    //评论量
    let commentNum = computed(() => {
      // 获取缓存中的文章列表
      let postsList = store.getters["postInfo/postsList"];

      // 判断缓存中是否有此文章
      let isHas = postsList.filter((item) => item.id == article.value.id);

      if (isHas.length) {
        return isHas[0].commentNum;
      }
      return article.value.commentNum;
    });

    //点赞状态
    let isLikedMe = computed(() => {
      // 获取缓存中的文章列表
      let postsList = store.getters["postInfo/postsList"];

      // 判断缓存中是否有此文章
      let isHas = postsList.filter((item) => item.id == article.value.id);

      if (isHas.length) {
        return isHas[0].isLikedMe;
      }
      return article.value.isLikedMe;
    });

    // 执行点赞传入回调
    let likeFn = (id) => {
      useLikes.likeFn(
        (type) => {
          // 取消
          if (type == "cancel") {
            article.value.likeNum--;
            article.value.isLikedMe = false;
            return;
          }
          // 点赞
          article.value.likeNum++;
          article.value.isLikedMe = true;
        },
        "posts",
        id
      );
    };

    // 点击评论跳转到文章
    let pingFn = () => {
      store.commit("postInfo/UP_isFromComment", true);
      toPosts();
    };

    // const target = ref(null);
    // console.log(target);
    // // 1. stop 是一个函数。如果调用它，就会停止观察（是否进入或移出可视区域的行为）
    // // 2. target 是观察的目标容器 dom对象 | 组件对象
    // // 3. isIntersecting 是一个bool值，表示是否进入可视区域。 true表示 进入 false表示 移出
    // const { stop } = useIntersectionObserver(
    //   target, // target 是vue的对象引用。是观察的目标
    //   // isIntersecting 是否进入可视区域，true是进入 false是移出
    //   // observerElement 被观察的dom
    //   ([{ isIntersecting }], observerElement) => {
    //     console.log(observerElement);
    //     // 在此处可根据isIntersecting来判断，然后做业务
    //     if (isIntersecting) {
    //       // 目标可见，
    //       console.log(132);
    //       // 1. ajax可以发了，后续不需要观察了
    //       stop();
    //       // // 2. 执行函数
    //       // fn();
    //     }
    //   }
    // );

    return {
      likeNum,
      commentNum,
      isLikedMe,
      likeFn,
      pingFn,
      toPosts,
      article,
      // target
    };
  },
};
</script>

<style>
.articleList {
  width: 100%;
  height: auto;
  background-color: var(--white_divBgc);
  /* margin: 30px; */
  /* box-sizing: border-box; */
  border-radius: 10px 10px 0 0;
}

.des {
  color: var(--white_divFc);
  padding: 5px 10px;
  font-size: 16px;
}

.tool {
  font-size: 24px;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tool .el-button {
  padding: 5px 8px !important;
}

.title {
  padding: 10px;
  font-size: 20px;
  font-weight: 550;
  color: var(--white_divFc);
}

.time {
  padding: 0 10px;
  font-size: 14px;
  color: var(--white_divFc);
}

.img {
  width: 100%;
  height: auto;
  min-height: 150px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.1%;
}

.img .el-image {
  width: 100%;
  border-radius: 10px 10px 0 0;
  transition: all 0.4s;
}
</style>
