<!--
 * @Author: your name
 * @Date: 2022-05-01 11:23:17
 * @LastEditTime: 2022-05-07 19:05:18
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\pages\user\index.vue
-->
<template>
  <div class="userMain">
    <div class="userInfo2">
      <!-- 头像用户名 -->
      <div class="userInfo_top">
        <div class="top_avatar">
          <img :src="state?.userInfo?.avatar" alt="" />
        </div>
        <div class="top_name">
          {{ state?.userInfo?.username }}
        </div>
        <div class="top_follow">
          <div>关注（{{ state?.userInfo?.followedNum }}）</div>
          <div>粉丝（{{ state?.userInfo?.fansNum }}）</div>
        </div>
      </div>
      <!-- 个人简介 -->
      <div class="userInfo_bottom">邮箱：{{ state?.userInfo?.email }}</div>
    </div>
    <HotTools
      :articleList="state.postInfo.data"
      :articleTotal="state.postInfo.total"
      :imgList="state.imgInfo.data"
      :imgTotal="state.imgInfo.total"
      @getImgFn="getImgFn"
      @getMore="getMore"
    >
    </HotTools>
  </div>
</template>

<script setup>
import { getUserInfo, getImgById, getPostById } from "@/api/api.js";
import { loginOut } from "@/utils/user";
import useLoading from "@/hooks/useLoading.js";
import { computed, onActivated, onMounted, reactive, toRefs } from "vue-demi";
import { useRoute, onBeforeRouteUpdate, useRouter } from "vue-router";
import HotTools from "./components/hotTools.vue";
import useScroll from "@/pages/home/useScroll.js";
import { useHead } from "@vueuse/head";

// 导航守卫
onBeforeRouteUpdate(async (to, from, next) => {
  // 别人的主页可随便进
  if (to.params.id) {
    let { code } = await getUserInfo({ userId: to.params.id });
    if (code == 404) {
      if (!from.name) {
        next("/404");
      }
      next("/404");
      return;
    } else {
      next();
    }
    return;
  }
  next();
});

//开启loading
let loading = useLoading();
useScroll(loading);
let route = useRoute();
let state = reactive({
  userInfo: {},
  imgInfo: {
    limit: 15,
    current: 1,
    data: [],
    total: 0,
    pageSize: 0,
  },
  postInfo: {
    limit: 5,
    current: 1,
    data: [],
    total: 0,
  },
});

// 用户信息
let getUserInfoFn = async () => {
  let { data: userInfo, code } = await getUserInfo({ userId: route.params.id });
  if (code === 403) {
    loginOut();
    return;
  }
  state.userInfo = userInfo;
};
// 用户发表的图片数量
let getImgInfoNum = async () => {
  let { total } = await getImgById({
    id: state?.userInfo?.id,
    limit: state.imgInfo.limit,
    current: state.imgInfo.current,
  });
  state.imgInfo.total = total;
};
//用户发表的图片列表
let getImgInfoFn = async () => {
  let {
    data: imgInfo,
    total: total1,
    pageSize,
  } = await getImgById({
    id: state?.userInfo?.id,
    limit: state.imgInfo.limit,
    current: state.imgInfo.current,
  });
  for (let i = 0; i < imgInfo.length; i++) {
    state.imgInfo.data.push(imgInfo[i]);
  }
  state.imgInfo.total = total1;
  state.imgInfo.pageSize = pageSize;
  return imgInfo;
};
//用户发表的文章列表
let getPostInfoFn = async () => {
  let {
    data: postInfo,
    total,
    pageSize,
  } = await getPostById({
    id: state.userInfo?.id,
    limit: state.postInfo.limit,
    current: state.postInfo.current,
  });
  state.postInfo.data = [...state.postInfo.data, ...postInfo];
  state.postInfo.total = total;
  return pageSize;
};
// 获取更多
let getMore = async (without) => {
  state.postInfo.current++;
  let pageSize = await getPostInfoFn();
  if (pageSize == state.postInfo.current) {
    without && without();
  }
};

// 追加图片 without：是否到达最后一页
let getImgFn = async (pushDom, without) => {
  await getUserInfoFn();
  let len = state.imgInfo.data.length;
  let data = await getImgInfoFn();
  await pushDom(len, data.length);
  if (state.imgInfo.current == state.imgInfo.pageSize) {
    without(true);
    return;
  }
  without(false);
  state.imgInfo.current++;
};

onMounted(async () => {
  await getUserInfoFn();
  await getPostInfoFn();
  await getImgInfoNum();
});

onActivated(() => {
  useHead({
    title: computed(() => `${state.userInfo?.username}的个人主页`),
  });
});
</script>

<style lang="scss" scoped>
.userMain {
  height: auto;
  width: 35%;
  min-width: 700px;
  background-color: var(--white_divBgc);
  color: var(--white_divFc);
  position: relative;
  z-index: 1;

  margin: 0 auto;
  div {
    box-sizing: border-box;
  }
  .userInfo2 {
    width: 100%;
    // border: 1px solid red;
    height: 400px;
    .userInfo_top {
      height: 80%;
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: var(--white_divBgc);
      border: 1px solid var(--white_divBoc);

      // background-image:url('https://boke.baymax.top/public/1650518327457.webp') ;
      // background-size: 100% 100% ;
      .top_avatar {
        // border: 1px solid skyblue;
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
      .top_name {
        // border: 1px solid skyblue;
        width: 100%;
        height: 30px;
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .top_follow {
        // border: 1px solid skyblue;
        width: 100%;
        height: 30px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          margin: 5px;
          font-size: 14px;
          color: var(--white_divFc);
        }
      }
    }
    .userInfo_bottom {
      height: 20%;
      border: 1px solid var(--white_divBoc);
      border-top: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 768px) {
  .userMain {
    height: auto;
    width: 100% !important;
    min-width: 200px;
    margin: 0px auto;
    background-color: var(--white_divBgc);
    box-sizing: border-box;
  }
  .userInfo2 {
    height: 250px !important;
  }
}
</style>